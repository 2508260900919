import './App.css';
import AllRoutes from './Components/AllRoutes/AllRoutes';
import Header from './Components/Header/Header';
import AppContext from "./utils/context.js";


function App() {
  return (
    <AppContext>
    <div className="App">
      <Header />
      <AllRoutes />
    </div>
    </AppContext>
  );
}

export default App;
