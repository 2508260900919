import React, { useState, useContext, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Context } from '../../../utils/context';

const Twotab = () => {
  const [data, setData] = useState({
    firstRowData: [],
    secondRowData: [],
    thirdRowData: [],
    fourthRowData: [],
    fifthRowData: [],
    sixthRowData: [],
    seventhRowData: [],
    eighthRowData: [],
    ninethRowData: [],
    tenthRowData: []
  });

  const { getData, currentSession } = useContext(Context);

  const transformData = (rowData) => {
  const blankPositions = rowData.blank ? rowData.blank.split(',').map(Number) : [];
  const mergePositions = rowData.merge ? rowData.merge.split(',').map(Number) : [];

  // Initialize transformed data and merged values
  const transformedData = { ...rowData };
  const mergedValues = [];

  // Replace blank positions with '--' and collect values for merging
  blankPositions.forEach(pos => {
    if (transformedData[pos] !== undefined) {
      mergedValues.push(transformedData[pos]);
      transformedData[pos] = '--';
    }
  });

  // If there are merge positions, sort and include them
  if (mergePositions.length) {
    const mergePosition = mergePositions[0]; // Consider the first merge position
    if (transformedData[mergePosition] !== undefined) {
      mergedValues.push(transformedData[mergePosition]);
      transformedData[mergePosition] = mergedValues
        .sort((a, b) => a - b) // Sort in increasing order
        .join(','); // Join values with a comma
    }
  }

  return transformedData;
};


  const getBlog = async () => {
    const res = await getData(`/getstoreData?type=${2}`);
    if (res?.success) {
      setData({
        firstRowData: transformData(res?.data?.firstRowData),
        secondRowData: transformData(res?.data?.secondRowData),
        thirdRowData: transformData(res?.data?.thirdRowData),
        fourthRowData: transformData(res?.data?.fourthRowData),
        fifthRowData: transformData(res?.data?.fifthRowData),
        sixthRowData: transformData(res?.data?.sixthRowData),
        seventhRowData: transformData(res?.data?.seventhRowData),
        eighthRowData: transformData(res?.data?.eighthRowData),
        ninethRowData: transformData(res?.data?.ninethRowData),
        tenthRowData: transformData(res?.data?.tenthRowData)
      });
    } else {
      console.error('Error fetching data:', res?.error);
    }
  };

  useEffect(() => {
    getBlog();
  }, [currentSession]);

  const renderRowData = (rowData) => {
    return [...Array(10).keys()].map((key) => (
      <td key={key}>{rowData[key]}</td>
    ));
  };

  const renderMergedValue = (value) => {
    if (typeof value === 'string' && value.includes(',')) {
      return value.split(',').map((v, index) => (
        <span key={index}>{v}<br /></span>
      ));
    }
    return value;
  };

  const rowLabels = [0,10, 20, 30, 40, 50, 60, 70, 80, 90];

  // Create an array to access rowData by index
  const rowDataArray = [
    data.firstRowData,
    data.secondRowData,
    data.thirdRowData,
    data.fourthRowData,
    data.fifthRowData,
    data.sixthRowData,
    data.seventhRowData,
    data.eighthRowData,
    data.ninethRowData,
    data.tenthRowData
  ];


  return (
    <>
      <section className='singletab'>
        <div className='container-fluid'>
          <div className='tableMain'>
            <Table className='tablemaintwo' responsive stripped>
              <thead>
                <tr>
                  <th style={{ background: "transparent" }}></th>
                  <th>0</th>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                  <th>5</th>
                  <th>6</th>
                  <th>7</th>
                  <th>8</th>
                  <th>9</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>00</td>
                  {renderRowData(data.firstRowData).map((cell, index) => (
                    <td key={index}>{renderMergedValue(cell.props.children)}</td>
                  ))}
                </tr>
                <tr>
                  <td>10</td>
                  {renderRowData(data.secondRowData).map((cell, index) => (
                    <td key={index}>{renderMergedValue(cell.props.children)}</td>
                  ))}
                </tr>
                <tr>
                  <td>20</td>
                  {renderRowData(data.thirdRowData).map((cell, index) => (
                    <td key={index}>{renderMergedValue(cell.props.children)}</td>
                  ))}
                </tr>
                <tr>
                  <td>30</td>
                  {renderRowData(data.fourthRowData).map((cell, index) => (
                    <td key={index}>{renderMergedValue(cell.props.children)}</td>
                  ))}
                </tr>
                <tr>
                  <td>40</td>
                  {renderRowData(data.fifthRowData).map((cell, index) => (
                    <td key={index}>{renderMergedValue(cell.props.children)}</td>
                  ))}
                </tr>
                <tr>
                  <td>50</td>
                  {renderRowData(data.sixthRowData).map((cell, index) => (
                    <td key={index}>{renderMergedValue(cell.props.children)}</td>
                  ))}
                </tr>
                <tr>
                  <td>60</td>
                  {renderRowData(data.seventhRowData).map((cell, index) => (
                    <td key={index}>{renderMergedValue(cell.props.children)}</td>
                  ))}
                </tr>
                <tr>
                  <td>70</td>
                  {renderRowData(data.eighthRowData).map((cell, index) => (
                    <td key={index}>{renderMergedValue(cell.props.children)}</td>
                  ))}
                </tr>
                <tr>
                  <td>80</td>
                  {renderRowData(data.ninethRowData).map((cell, index) => (
                    <td key={index}>{renderMergedValue(cell.props.children)}</td>
                  ))}
                </tr>
                <tr>
                  <td>90</td>
                  {renderRowData(data.tenthRowData).map((cell, index) => (
                    <td key={index}>{renderMergedValue(cell.props.children)}</td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
          <div className='tableMainmobile'>
            {/* Mobile View */}
            <Table className='tablemainMobile' responsive stripped>
              <thead>
                <tr>
                  <th>Row</th>
                  {[...Array(10).keys()].map(key => <th key={key}>{key}</th>)}
                </tr>
              </thead>
              <tbody>
                {rowLabels.map((label, index) => (
                  <tr key={index}>
                    <td>{label}</td>
                    {renderRowData(rowDataArray[index] || []).map((cell, cellIndex) => (
                      <td key={cellIndex}>{renderMergedValue(cell.props.children)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Twotab;
