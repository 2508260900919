import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Home/Home'
import Chartab from '../MainComponenttabs/Chartab/Chartab'

const AllRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/result" element={<Chartab />}/>
            </Routes>
        </>
    )
}

export default AllRoutes