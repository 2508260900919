import React, { useState, useEffect, useContext } from 'react';
import "./Header.css";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Col, Row } from 'react-bootstrap';
import Resultmodal from '../Commonmodal/Resultmodal/Resultmodal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import MobileHeader from '../Commonmodal/Offcanvas/MobileHeader/MobileHeader';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Context } from '../../utils/context';
import moment from 'moment';



const Header = () => {
    const { getData, postData, imageUrl, setDate, date, setCurrentSession, currentSession, setCurrentSessionMinute, setCurrentSessionSecond } = useContext(Context);
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false);
    // const [date, setDate] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    const [minutes, setMinutes] = useState("00");
    const [seconds, setSeconds] = useState("00");
    const [currentDate, setCurrentDate] = useState('0');
    const [currentTime, setCurrentTime] = useState('0');

    function currentRunningSecond() {
        const now = new Date();
        const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        return (now - midnight) / 1000; // seconds since midnight
    }

    // Helper function to pad numbers
    function padTime(time) {
        return time.toString().padStart(2, '0');
    }
    const handleIconClick = () => {
        setDate(new Date());
    };

    const handleDateChange = (e) => {
        const selectedDate = e.value;
        setDate(selectedDate);
    };

    const formatDate = (date) => {
        return date ? moment(date).format('YYYY-MM-DD') : '';
    };

    function updateTimer() {
        const totalSeconds = 900 - (currentRunningSecond() % 900);
        const computedMinutes = padTime(Math.floor(totalSeconds / 60));
        const computedSeconds = padTime(Math.floor(totalSeconds % 60));
        if (computedMinutes == '00' && computedSeconds == '01') {
            const min = 1;
            const max = 100;
            const rand = min + Math.random() * (max - min);
            setCurrentSession(rand);
            // console.log(currentSession,"currentSession");
        }
        setMinutes(computedMinutes);
        setCurrentSessionMinute(computedMinutes);
        setSeconds(computedSeconds);
        setCurrentSessionSecond(computedSeconds);
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // const handleIconClick = () => {
    //     setIsOpen(!isOpen);
    // };

    useEffect(() => {
        updateTimer(); // Initial update
        const interval = setInterval(() => {
            updateTimer(); // Update every second
        }, 1000); // Update every second

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);

    useEffect(() => {
        const currentDate = new Date();
        const targetDate = new Date(currentDate.getTime() + 15 * 60000);

        const calculateTimeLeft = () => {
            const now = new Date();
            const difference = targetDate - now;

            let timeLeft = {};

            if (difference > 0) {
                timeLeft = {
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
                };
            }

            return timeLeft;
        };

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <>
            <section className='header'>
                <div className='desktopHeader'>
                    <div className='headerMainnav'>
                        <Navbar className="backNav">
                            <Container fluid>
                                <Navbar.Brand href="#home">
                                    <div className=''>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} className='headlogo' alt="logo" />
                                    </div>
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <FontAwesomeIcon icon={faBars} className='barIcon' onClick={handleShow} />
                                <Navbar.Collapse className="justify-content-end mobileNavvv">
                                    <div>
                                        <p className='nextgameinp'>Next Game Result In</p>
                                    </div>
                               
                                    <div>                                   
                                        <div className='rightMainsec'>
                                           
                                            <Row>
                                                
                                                <Col xl={3} md={3} xs={3}>
                                                    <div className='timesecmain'>
                                                        <div className='timeinfo'>
                                                            <p>{timeLeft.days}</p>
                                                        </div>
                                                        <div>Days</div>
                                                    </div>
                                                </Col>
                                                <Col xl={3} md={3} xs={3}>
                                                    <div className='timesecmain'>
                                                        <div className='timeinfo'>
                                                            <p>{timeLeft.hours}</p>
                                                        </div>
                                                        <div>Hours</div>
                                                    </div>
                                                </Col>
                                                <Col xl={3} md={3} xs={3}>
                                                    <div className='timesecmain'>
                                                        <div className='timeinfo'>
                                                            <p>{minutes}</p>
                                                        </div>
                                                        <div>Minutes</div>
                                                    </div>
                                                </Col>
                                                <Col xl={3} md={3} xs={3}>
                                                    <div className='timesecmain'>
                                                        <div className='timeinfo'>
                                                            <p>{seconds}</p>
                                                        </div>
                                                        <div>Seconds</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='rightMainsectwo'>
                                            <Row className='rightMainrow'>
                                                <Col xl={6} md={6} xs={6} className='px-2'>
                                                    <div className='timesecmain'>
                                                        <div className='timeinfocircle'>
                                                            <Calendar
                                                                value={date}
                                                                onChange={handleDateChange}
                                                                dateFormat="dd/mm/yy"
                                                                maxDate={new Date()}
                                                            />
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon3.png"} onClick={handleIconClick} className='iconImg' alt="icon2" />
                                                        </div>
                                                    </div>
                                                </Col>
                                                {/* <Col xl={4} md={4} xs={4}>
                                                    <div className='timesecmain'>
                                                        <div className='timeinfocircle'>
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon1.png"} className='iconImg' alt="icon1" onClick={() => setModalShow(true)} />
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                <Col xl={6} md={6} xs={6}  className='px-2'>
                                                    <Link to="/">
                                                        <div className='timesecmain'>
                                                            <div className='timeinfocircle'>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon2.png"} className='iconImg' alt="icon2" />
                                                                {/* <Calendar value={date} onChange={(e) => setDate(e.value)} dateFormat="dd/mm/yy" /> */}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </div>
                </div>

                <div className='mobileHeader'>

                    <div className='headerMainnav'>
                        <Navbar className="backNav">
                            <Container fluid>
                                <Navbar.Brand href="#home">
                                    <div className=''>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} className='headlogo' alt="logo" />
                                    </div>
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <div className='timesecmain'>
                                    <div className='rightMainsectwo'>
                                        <Row className='rightMainrow'>
                                            <Col xl={6} md={6} xs={6}>
                                                <div className='Iconsecmain'>
                                                    <div className='timeinfocircle'>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon3.png"} onClick={handleIconClick} className='iconImg' alt="icon2" />
                                                        <Calendar value={date} onChange={(e) => setDate(e.value)} dateFormat="dd/mm/yy" />
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col xl={6} md={6} xs={6}>
                                                <div className='Iconsecmain'>
                                                    <div className='timeinfocircle'>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon2.png"} className='iconImg' onClick={() => setModalShow(true)} />
                                                    </div>

                                                </div>
                                            </Col>
                                            {/* <Col xl={4} md={4} xs={4}>
                                                <div className='Iconsecmain'>
                                                    <div className='timeinfocircle'>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon3.png"} className='iconImg' />
                                                    </div>

                                                </div>
                                            </Col> */}

                                        </Row>
                                    </div>

                                </div>

                                <Navbar.Collapse className="justify-content-end mobileNavvv">

                                    {/*  */}
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>

                        <div className='rightMainsec'>
                        
                            <Row>
                            <Col md={12} className='text-center'> <p className='nextgameinp'>Next Game Result In</p>
                                                </Col>
                                <Col xl={3} md={3} xs={3}>
                                    <div className='timesecmain'>
                                        <div className='timeinfo'>
                                            <p>{timeLeft.days}</p>
                                        </div>
                                        <div>Days</div>
                                    </div>
                                </Col>
                                <Col xl={3} md={3} xs={3}>
                                    <div className='timesecmain'>
                                        <div className='timeinfo'>
                                            <p>{timeLeft.hours}</p>
                                        </div>
                                        <div>Hours</div>
                                    </div>
                                </Col>
                                <Col xl={3} md={3} xs={3}>
                                    <div className='timesecmain'>
                                        <div className='timeinfo'>
                                            <p>{minutes}</p>
                                        </div>
                                        <div>Minutes</div>
                                    </div>
                                </Col>
                                <Col xl={3} md={3} xs={3}>
                                    <div className='timesecmain'>
                                        <div className='timeinfo'>
                                            <p>{seconds}</p>
                                        </div>
                                        <div>Seconds</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </div>

            </section>
            <MobileHeader placement={"top"} name="start" show={show} handleClose={handleClose} />
            <Resultmodal show={modalShow} onHide={() => setModalShow(false)} handleClose={handleClose} />
        </>
    );
};

export default Header;
