import React, { useState, useContext, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import "./Singletab.css";
import { Context } from '../../../utils/context';

const Singletab = ({ setPageType }) => {
  const [data, setData] = useState([]);
  const { getData, currentSession } = useContext(Context);

  const getBlog = async () => {
    const res = await getData(`/getstoreData?type=${1}`);
    if (res?.success) {
      const rows = [
        'firstRowData', 'secondRowData', 'thirdRowData', 'fourthRowData', 
        'fifthRowData', 'sixthRowData', 'seventhRowData', 'eighthRowData', 
        'ninethRowData', 'tenthRowData'
      ];
      const transformedData = rows.map(row => transformData(res?.data?.[row]));
      setData(transformedData);
    } else {
      console.error('Error fetching data:', res?.error);
    }
  };

  const transformData = (rowData) => {
    if (!rowData) return {};
  
    const blankIndices = rowData.blank ? rowData.blank.split(',').map(Number) : [];
    const mergeIndex = rowData.merge ? Number(rowData.merge) : null;
  
    const transformedRow = {};
    for (let i = 0; i <= 9; i++) {
      if (blankIndices.includes(i)) {
        transformedRow[i] = '--';
      } else if (i === mergeIndex) {
        // Collect and sort merged values including the merge index value
        const mergedValues = [
          rowData[i], // Include the value at mergeIndex
          ...blankIndices.filter(index => index !== i).map(index => rowData[index])
        ].sort((a, b) => a - b); // Sort in increasing order
  
        transformedRow[i] = (
          <div className="merged-values">
            {mergedValues.map((value, idx) => (
              <React.Fragment key={idx}>
                {value}
                <br />
              </React.Fragment>
            ))}
          </div>
        );
      } else {
        transformedRow[i] = rowData[i];
      }
    }
    return transformedRow;
  };
  
  

  useEffect(() => {
    if (currentSession) {
      getBlog();
    }
  }, [currentSession]);

  const renderRow = (rowData, index) => (
    <tr key={index}>
      <td>{index * 10}</td>
      {Object.keys(rowData).map(key => (
        <td key={key}>{rowData[key]}</td>
      ))}
    </tr>
  );

  return (
    <section className='singletab'>
      <div className='container-fluid'>
        <div className='tableMain'>
          <Table className='tablemaintwo' responsive stripped>
            <thead>
              <tr>
                <th style={{ background: "transparent", border: "none" }}></th>
                {[...Array(10).keys()].map(key => <th key={key}>{key}</th>)}
              </tr>
            </thead>
            <tbody>
              {data.map(renderRow)}
            </tbody>
          </Table>
        </div>
        <div className='tableMainmobile '>
        <Table className='tablemainMobile' responsive stripped>
        <thead>
              <tr>
                <th style={{ background: "transparent"  , border: "none" }}></th>
                {[...Array(10).keys()].map(key => <th key={key}>{key}</th>)}
              </tr>
            </thead>
            <tbody>
              {data.map(renderRow)}
            </tbody>
          </Table>
          </div>
      </div>
    </section>
  );
};

export default Singletab;
