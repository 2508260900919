import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Resultmodal from '../../Resultmodal/Resultmodal';
import "./MobileHeader.css";
const MobileHeader = (props) => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <div className='offMain'>
                <Offcanvas show={props.show} onHide={props.handleClose} {...props}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <section className='MobileHeader'>
                            <div className='MobileHeaderMainnav'>
                                <div>
                                    <div className='rightMainsec'>
                                        <Row>
                                            <Col xl={3} md={3} xs={3}>
                                                <div className='timesecmain'>
                                                    <div className='timeinfo'>
                                                        <p>00</p>
                                                    </div>
                                                    <div>Days</div>
                                                </div>
                                            </Col>
                                            <Col xl={3} md={3} xs={3}>
                                                <div className='timesecmain'>
                                                    <div className='timeinfo'>
                                                        <p>00</p>
                                                    </div>
                                                    <div>Hours</div>
                                                </div>
                                            </Col>
                                            <Col xl={3} md={3} xs={3}>
                                                <div className='timesecmain'>
                                                    <div className='timeinfo'>
                                                        <p>00</p>
                                                    </div>
                                                    <div>Minutes</div>
                                                </div>
                                            </Col>
                                            <Col xl={3} md={3} xs={3}>
                                                <div className='timesecmain'>
                                                    <div className='timeinfo'>
                                                        <p>00</p>
                                                    </div>
                                                    <div>Second</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                </div>

                                <div>
                                    <div className='rightMainsectwo'>
                                        <Row className='rightMainrow'>
                                            <Col xl={4} md={4} xs={4}>
                                                <div className='Iconsecmain'>
                                                    <div className='timeinfocircle'>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon1.png"} className='iconImg' />
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col xl={4} md={4} xs={4}>
                                                <div className='Iconsecmain'>
                                                    <div className='timeinfocircle'>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon2.png"} className='iconImg' onClick={() => { setModalShow(true); props.handleClose() }} />
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col xl={4} md={4} xs={4}>
                                                <div className='Iconsecmain'>
                                                    <div className='timeinfocircle'>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon3.png"} className='iconImg' />
                                                    </div>

                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                </div>

                            </div>
                        </section >
                    </Offcanvas.Body>
                </Offcanvas>
            </div>


            <Resultmodal show={modalShow}
                onHide={() => setModalShow(false)} />
        </>
    )
}

export default MobileHeader