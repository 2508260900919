import React, { useState, useContext, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import "./Home.css";
import Singletab from '../MainComponenttabs/Singletab/Singletab';
import Fulltab from '../MainComponenttabs/Fulltab/Fulltab';
import Chartab from '../MainComponenttabs/Chartab/Chartab';
import Twotab from '../MainComponenttabs/Twotab/Twotab';
import { Link } from "react-router-dom";
import moment from 'moment';
import { Context } from '../../utils/context.js';
// import Time from 'react-datepicker/dist/time.js';

const Home = () => {
    const { getData, postData, imageUrl ,currentSession,currentSessionMinute,currentSessionSecond} = useContext(Context);

    const [data, setData] = useState({});
    
    const [pageType, setPageType] = useState(1);
    const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
    
   
    useEffect(() => {
    }, []);
    useEffect(() => {
        setCurrentDate(moment().format('DD-MM-YYYY'));
    }, []);
    return (
        <>
            <section className='Mainhometabs'>
                <div className='container-fluid p-0'>
                    <div className='homeMain'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="1" >

                            <Nav variant="pills" className="flex-row homeNav">
                                <Nav.Item >
                                    <Nav.Link eventKey="1" className='tabsss'>Single</Nav.Link>
                                </Nav.Item>
                                <Nav.Item >
                                    <Nav.Link eventKey="2" className='tabsss'>Two</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item >
                                    <Nav.Link eventKey="3" className='tabsss'>Full</Nav.Link>
                                </Nav.Item> */}
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="4">Result</Nav.Link>
                                </Nav.Item> */}
                                <Nav.Item >
                                    <Nav.Link as={Link} to="/result" eventKey="4">Result</Nav.Link>
                                </Nav.Item>
                            </Nav>


                            <div className='white-bgggggg'>
                                <p>
                                <span className='me-2'>{currentDate}</span>
                                    {/* <span className='me-2'>{currentSessionMinute}</span>
                                    <span>{currentSessionSecond}</span> */}
                                </p>
                            </div>

                            <Tab.Content >

                                <Tab.Pane eventKey="1">
                                    <Singletab setPageType={setPageType} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="2">
                                    <Twotab setPageType={setPageType} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="3">
                                    <Fulltab setPageType={setPageType} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="4">
                                    <Chartab setPageType={setPageType} />
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Home