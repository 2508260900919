import React, { useEffect, useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import './Chartab.css';
import { Tab, Nav } from 'react-bootstrap';
import { Context } from '../../../utils/context';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from "react-js-loader";

const Chartab = () => {
    const { getData, currentSession, date } = useContext(Context);
    const [shimmerLoader, setShimmerLoader] = useState(false);
    const [datas, setDatas] = useState([]);
    const [tablId, setTableId] = useState("firstData");
    const [newdatas, setNewDatas] = useState([]);
    const [type, setType] = useState("1");
    const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
    const [latestTime, setLatestTime] = useState("");

    const navItems = [
        { eventKey: "firstData", label: "00" },
        { eventKey: "secondData", label: "10" },
        { eventKey: "thirdData", label: "20" },
        { eventKey: "fourthData", label: "30" },
        { eventKey: "fifthData", label: "40" },
        { eventKey: "sixthData", label: "50" },
        { eventKey: "seventhData", label: "60" },
        { eventKey: "eighthData", label: "70" },
        { eventKey: "ninethData", label: "80" },
        { eventKey: "tenthData", label: "90" },
    ];

    const formatDate = (date) => date ? moment(date).format('YYYY-MM-DD') : '';

    const headerMappings = {
        "firstData": ["Time", "00", "01", "02", "03", "04", "05", "06", "07", "08", "09"],
        "secondData": ["Time", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
        "thirdData": ["Time", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"],
        "fourthData": ["Time", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39"],
        "fifthData": ["Time", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49"],
        "sixthData": ["Time", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"],
        "seventhData": ["Time", "60", "61", "62", "63", "64", "65", "66", "67", "68", "69"],
        "eighthData": ["Time", "70", "71", "72", "73", "74", "75", "76", "77", "78", "79"],
        "ninethData": ["Time", "80", "81", "82", "83", "84", "85", "86", "87", "88", "89"],
        "tenthData": ["Time", "90", "91", "92", "93", "94", "95", "96", "97", "98", "99"],
    };

    const [tableHeaders, setTableHeaders] = useState(headerMappings[tablId]);

    const getBlog = async (type, tablId) => {
        setShimmerLoader(true);
        const res = await getData(`/getResult?type=${type}&date=${formatDate(date)}&tablId=${tablId}`);
        if (res?.success) {
            setDatas(res?.data);
            setNewDatas(res?.data);
            if (res?.data[0]?.datetime) {
                const datetime = res.data[0].datetime;
                const formattedTime = moment(datetime, 'HH:mm:ss').format('hh:mm A'); // Format time
                setLatestTime(formattedTime);
            } else {
                setLatestTime("Invalid datetime");
            }
            setShimmerLoader(false);
        } else {
            setShimmerLoader(false);
            console.error('Error fetching data:', res?.error);
        }
    }

    const handleClick = (e) => {
        setType(e);
    };

    const handleClicks = (e) => {
        setTableId(e);
    };

    useEffect(() => {
        setTableHeaders(headerMappings[tablId] || []);
        getBlog(type, tablId);
    }, [type, tablId, currentSession, formatDate(date)]);
    
    useEffect(() => {
        setCurrentDate(moment().format('DD-MM-YYYY'));
    }, []);

    const RenderTable = ({ tableHeaders, newdatas }) => {
        return (
            <Table className='tablemaintwo' responsive striped>
                <thead>
              
                    <tr>
                        {tableHeaders.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {newdatas?.map((datas, index) => (
                        <React.Fragment key={index}>
                            <tr className=''>
                                <td>{ moment(datas?.datetime, 'HH:mm:ss').format('hh:mm A')}</td>
                                {datas?.data?.map((item, itemIndex) => {
                                    let merged = ""; // Initialize merged variable
                                    return (
                                        <React.Fragment key={itemIndex}>
                                            {item?.results && Object.entries(item.results).map(([key, result], resultIndex) => (
                                                result !== null ? (
                                                    <td key={resultIndex}>
                                                        {typeof result === 'string' && result.includes('-') && result !== '--' ? (
                                                            result.split('-')
                                                                .map(Number)
                                                                .sort((a, b) => a - b)
                                                                .map((num, index) => (
                                                                    <p key={index}>{num}</p>
                                                                ))
                                                        ) : (
                                                            <p>
                                                                {item?.blank.split(',')?.map(Number)?.includes(parseInt(key)) ?
                                                                    (() => {
                                                                        merged += " " + result; // Concatenate result to merged
                                                                        return '--'; // Render '--' when condition is true
                                                                    })() :
                                                                    item?.merge === key ? (
                                                                        <React.Fragment>
                                                                            
                                                                            {[result, ...merged.split(' ')].sort((a, b) => Number(a) - Number(b)).map((word, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    {word}
                                                                                    {index !== 0 && (<br />)}    
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </React.Fragment>
                                                                    ) : result // Render result as it is when condition is false
                                                                }
                                                            </p>
                                                        )}
                                                    </td>
                                                ) : null
                                            ))}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
        );
    };

    return (
        <section className='charttab Mainhometabs singletab'>
            <div className='container-fluid p-0'>
                <div className='homeMain '>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                        <Nav variant="pills" className="flex-row homeNav">
                            <Nav.Item>
                                <Nav.Link eventKey="1" className='tabsss' onClick={() => handleClick("1")}>Single</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2" className='tabsss' onClick={() => handleClick("2")}>Two</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/" eventKey="4">Chart</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div className=' white-bgggggg'>
                            <p>
                           
                                <span className='me-2'>{currentDate}</span>
                                <span>{latestTime}</span>
                            </p>
                        </div>
                    </Tab.Container>
                </div>
                <div className='tableMain'>
                    <Tab.Container id="left-tabs-example">
                        <Nav variant="pills" className="flex-row tableflex">
                            {navItems.map(item => (
                                <Nav.Item className='tableNo' key={item.eventKey}>
                                    <Nav.Link eventKey={item.eventKey} onClick={() => handleClicks(item.eventKey)}>{item.label}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content>
                            {navItems.map(item => (
                                <Tab.Pane eventKey={item.eventKey} key={item.eventKey}></Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Tab.Container>
                    {shimmerLoader ? (
                        <Loader type="bubble-top" bgColor={"#fff"} color={"#fff"} title={"Loading..."} size={100} />
                    ) : (
                        <RenderTable tableHeaders={tableHeaders} newdatas={newdatas} />
                    )}
                </div>

                <div className='tablechartmobile tableMain'>
                    <Tab.Container id="left-tabs-example">
                        <Nav variant="pills" className="flex-row tableflex">
                            {navItems.map(item => (
                                <Nav.Item className='tableNo' key={item.eventKey}>
                                    <Nav.Link eventKey={item.eventKey} onClick={() => handleClicks(item.eventKey)}>{item.label}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content>
                            {navItems.map(item => (
                                <Tab.Pane eventKey={item.eventKey} key={item.eventKey}></Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Tab.Container>
                    {shimmerLoader ? (
                        <Loader type="bubble-top" bgColor={"#fff"} color={"#fff"} title={"Loading..."} size={100} />
                    ) : (
                        <RenderTable tableHeaders={tableHeaders} newdatas={newdatas} />
                    )}
                </div>
            </div>
        </section>
    );
}

export default Chartab;
